import { Input } from 'antd';
import styles from './searchbar.module.css';
import {useState} from 'react';
const { Search } = Input;

interface SearchBarProps {
    onSearch: (value: string) => Promise<[void, void]>;
}
const SearchBar = (props: SearchBarProps) => {
    const [loading, setLoading] = useState(false);
    return (
        <div className={styles.searchBar}>
            <Search
                style={{ fontSize: '3rem' }}
                size='large'
                placeholder="Search for shows and stars"
                onSearch={(value) => {
                    setLoading(true);
                    props.onSearch(value).then(() => {
                        setLoading(false);
                    })
                }}
                loading={loading}
            />
        </div>
    );
}
export default SearchBar;