import axios from "axios";
import { useEffect, useState } from "react";
import { Cast, Show } from "../../types";
import { useQuery } from "../application/app";
import { ShowsZone } from "../gridview/gridview";
import styles from "./castpage.module.css";

const CastPage = () => {
    const [cast, setCast] = useState<Cast | null>(null);
    const [shows, setShows] = useState<Show[]>([]);
    const query = useQuery();

    useEffect(() => {
        const id = query.get('id');
        if (id) {
            axios.get(`https://api.tvmaze.com/people/${id}`)
                .then(res => {
                    const foundCast = res.data;
                    setCast({
                        id: foundCast.id,
                        name: foundCast.name,
                        img: foundCast.image?.medium,
                        birthday: foundCast.birthday,
                        country: foundCast.country?.name,
                        gender: foundCast.gender
                    });
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }, [query]);
    useEffect(() => {
        const id = query.get('id');
        if (id) {
            axios.get(`https://api.tvmaze.com/people/${id}/castcredits?embed=show`)
                .then(res => {
                    const foundShows = res.data;
                    const shows = foundShows.map((show: any) => {
                        return {
                            id: show._embedded.show.id,
                            name: show._embedded.show.name,
                            img: show._embedded.show.image?.medium,
                        }
                    });
                    setShows(shows);
                })
                .catch(err => { 
                    console.log(err);
                });
        }
    }, [query]);

    return (
        <div className={styles.castPage}>
            <div className={styles.introduction}>
                <div className={styles.blur} style={{ backgroundImage: `url("${cast?.img}")` }}></div>
                <img src={cast?.img} className={styles.image} alt={cast?.name} />
                <div className={styles.showInfo}>
                    <div className={styles.name}>{cast?.name}</div>
                    <div className={styles.gender}>Gender: {cast?.gender}</div>
                    <div className={styles.birthday}>Birthday: {cast?.birthday}</div>
                    <div className={styles.country}>Country: {cast?.country}</div>
                </div>
            </div>
            {shows.length > 0 && <ShowsZone shows={shows} title="SHOWS ATTENDED" />}
        </div>
    );
}
export default CastPage;