import { Rate } from 'antd';
import { Cast, Episode, Season, Show } from '../../types';
import styles from './showpage.module.css';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useQuery } from '../application/app';
import { CastZone, SeasonEpisodesZone, SeasonsZone } from '../gridview/gridview';


const ShowPage = () => {
    const [show, setShow] = useState<Show>();
    const [seasons, setSeasons] = useState<Season[]>([]);
    const [chosenSeason, chooseSeason] = useState<Season | undefined>(undefined);
    const [seasonEpisodes, setSeasonEpisodes] = useState<Episode[]>([]);
    const [castList, setCastList] = useState<Cast[]>([]);
    const query = useQuery();

    useEffect(() => {
        const id = query.get('id');
        if (id) {
            axios.get(`https://api.tvmaze.com/shows/${id}`)
                .then(res => {
                    const show = res.data;
                    setShow({
                        id: show.id,
                        name: show.name,
                        img: show.image?.medium,
                        rating: show.rating?.average,
                        summary: show.summary?.replace(/<[^>]+>/g, ''),
                        genres: show.genres,
                    });
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }, [query]);
    useEffect(() => {
        const id = query.get('id');
        if (id) {
            axios.get(`https://api.tvmaze.com/shows/${id}/seasons`)
                .then(res => {
                    const fetchedSeasons = res.data;
                    const seasonsMap: Season[] = fetchedSeasons.map((season: any) => {
                        return {
                            id: season.id,
                            name: season.name.length > 0 ? season.name : 'Season ' + season.number,
                            img: season.image?.medium || show?.img,
                            showId: id
                        }
                    });
                    setSeasons(seasonsMap);
                    chooseSeason(seasonsMap[0]);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }, [query, show?.img]);
    useEffect(() => {
        if (chosenSeason) {
            axios.get(`https://api.tvmaze.com/seasons/${chosenSeason.id}/episodes`)
                .then(res => {
                    const episodes = res.data;
                    setSeasonEpisodes(episodes.map((episode: any) => {
                        return {
                            id: episode.id,
                            name: episode.name,
                            img: episode.image?.medium || chosenSeason.img,
                        }
                    }));
                })
                .catch(err => {
                    console.log(err);
                });
        }
        const seasonId = query.get('season');

        if (seasonId !== chosenSeason?.id?.toString()) {
            if (!seasonId) return;
            const foundSeason = seasons.find(season => season.id?.toString() === seasonId)
            if (foundSeason) {
                chooseSeason(foundSeason);
            }
        }
    }, [query, chosenSeason, seasons]);
    useEffect(() => {
        const id = query.get('id');
        if (id) {
            axios.get(`https://api.tvmaze.com/shows/${id}/cast`)
                .then(res => {
                    const newCastList = res.data;
                    setCastList(newCastList.map((cast: any) => {
                        return {
                            id: cast.person.id,
                            name: cast.person.name,
                            img: cast.person.image?.medium || show?.img,
                        }
                    }));
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }, [query, show?.img]);

    return (
        <div className={styles.showPage}>
            <div className={styles.introduction}>
                <div className={styles.blur} style={{ backgroundImage: `url("${show?.img}")` }}></div>
                <img src={show?.img} className={styles.image} alt={show?.name} />
                <div className={styles.showInfo}>
                    <div className={styles.name}>{show?.name}</div>
                    <Rate disabled defaultValue={(show?.rating || 0) / 2} />
                    <div className={styles.summary}> {show?.summary || 'There is no summary for this show.'}</div>
                </div>
            </div>
            {seasons.length > 0 && <SeasonsZone seasons={seasons} />}
            {seasonEpisodes.length > 0 && <SeasonEpisodesZone episodes={seasonEpisodes} seasonName={chosenSeason?.name || ''} />}
            {castList.length > 0 && <CastZone castList={castList} />}
        </div>
    );
}
export default ShowPage;
