import { Show, Cast, Season, Episode } from '../../types';
import { Link } from 'react-router-dom';
import styles from './gridview.module.css';

export const ShowsZone = (props: { shows: Show[], title: string }) => {
    return (
        <GridView title={props.title} id={styles.showsView}>
            {props.shows.map((show, index) => {
                return show.img && (
                    <Link to={`/show?id=${show.id}`} className={styles.item} key={index}>
                        <img src={show.img} className={styles.image} alt={show.name} />
                        <div className={styles.name}>{show.name}</div>
                    </Link>
                );
            })}
        </GridView>
    )
}
export const CastZone = (props: { castList: Cast[] }) => {
    return (
        <GridView title="CAST" id={styles.castListView}>
            {props.castList.map((cast, index) => {
                return cast.img && (
                    <Link to={`/cast?id=${cast.id}`} className={styles.item} key={index}>
                        <img src={cast.img} className={styles.image} alt={cast.name} />
                        <div className={styles.name}>{cast.name}</div>
                    </Link>
                );
            })}
        </GridView>
    )
}

export const SeasonsZone = (props: { seasons: Season[] }) => {
    return (
        <GridView title="SEASONS" id={styles.seasonsView}>
            {props.seasons.map((season, index) => {
                return season.img && (
                    <Link to={`/show?id=${season.showId}&season=${season.id}`} className={styles.item} key={index}>
                        <img src={season.img} className={styles.image} alt={season.name} />
                        <div className={styles.name}>{season.name}</div>
                    </Link>
                );
            })}
        </GridView>
    )
}

export const SeasonEpisodesZone = (props: { episodes: Episode[], seasonName: string }) => {
    return (
        <GridView title={props.seasonName.toUpperCase()} id={styles.episodesView} landscape>
            {props.episodes.map((episode, index) => {
                return episode.img && (
                    <div className={styles.item} key={index}>
                        <img src={episode.img} className={styles.image} alt={episode.name} />
                        <div className={styles.name}>{episode.name}</div>
                    </div>
                );
            })}
        </GridView>
    )
}


const GridView = (props: { title: string, children: any, id: string, landscape?:boolean }) => {
    return (
        <div className={styles.gridView + " " + (props.landscape && styles.landscape)} id={props.id}>
            <span>{props.title}</span>
            <div className={styles.gridContainer}>
                {props.children}
            </div>
        </div>
    );
}