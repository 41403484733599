import styles from './sidebar.module.css';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    HomeOutlined,
    StarOutlined,
    VideoCameraOutlined,
    UserOutlined,
    HourglassOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';


const SideBar = () => {

    const [visible, setVisible] = useState(false);
    const location = useLocation();

    return (
        <div
            className={styles.sidebar + " " + (visible ? styles.visible : styles.invisible)}
            onMouseEnter={() => { setVisible(true) }}
            onMouseLeave={() => { setVisible(false) }}>
            <Link to="#" className={styles.brand}>
                <HourglassOutlined className={styles.logo} />
                <span className={styles.logoText}>TVimer</span>
            </Link>
            <div className={styles.buttonGroup}>
                {/* <RouteButton
                    chosen={location.pathname === "/search"}
                    icon={<SearchOutlined className={styles.routeButtonImage} />}
                    url="/search" /> */}
                <RouteButton
                    chosen={location.pathname === "/"}
                    icon={<HomeOutlined className={styles.routeButtonImage} />}
                    url="/" />
                <RouteButton
                    chosen={location.pathname === "/show"}
                    icon={<VideoCameraOutlined disabled className={styles.routeButtonImage} />}
                    url="/show" />
                <RouteButton
                    chosen={location.pathname === "/cast"}
                    icon={<StarOutlined disabled className={styles.routeButtonImage} />}
                    url="/cast" />
                <RouteButton
                    chosen={location.pathname === "/user"}
                    icon={<UserOutlined className={styles.routeButtonImage} />}
                    url="/" />
            </div>
        </div >
    );
}
export default SideBar;


interface RouteButtonProps {
    icon: JSX.Element;
    chosen?: boolean;
    url: string;
    disabled?: boolean;
}
const RouteButton = (props: RouteButtonProps) => {
    return (
        <Link
            to={props.url}
            className={styles.routeButton + " " +
                (props.chosen && styles.switchOn) + " " +
                (props.disabled && styles.disabled)}>
            {props.icon}
        </Link>
    );
}
