import 'antd/dist/antd.min.css';
import styles from './app.module.css';
import SideBar from '../sidebar/sidebar';
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useMemo, useRef } from 'react';
import Homepage from '../homepage/homepage';
import ShowPage from '../showpage/showpage';
import CastPage from '../castpage/castpage';

export function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

const App = () => {
    const lastShowId = useRef<string | null>(null);
    const lastCastId = useRef<string | null>(null);
    const location = useLocation();
    const query = useQuery();
    const navigate = useNavigate();
    const currentId = query.get('id');

    if (currentId) {
        if (location.pathname === '/show' && currentId !== lastShowId.current)
            lastShowId.current = currentId;
        if (location.pathname === '/cast' && currentId !== lastCastId.current)
            lastCastId.current = currentId;
    }
    else {

        if (location.pathname === '/show')
            navigate(lastShowId.current ? `/show?id=${lastShowId.current}` : '/');
        if (location.pathname === '/cast')
            navigate(lastCastId.current ? `/cast?id=${lastCastId.current}` : '/');
    }

    return (
        <div className={styles.app}>
            <SideBar />
            <div className={styles.content}>
                <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route path="/show" element={<ShowPage />} />
                    <Route path="/cast" element={<CastPage />} />
                </Routes>
            </div>
        </div>
    );
}
export default App;







