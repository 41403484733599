import styles from './homepage.module.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import SearchBar from '../searchbar/searchbar';
import { Show, Cast } from '../../types';
import { ShowsZone, CastZone } from '../gridview/gridview';
import {useQuery } from '../application/app';
const getUpcomingShows = () => {
    const today = new Date();
    //https://api.tvmaze.com/schedule/web?date=2022-10-24
    return axios.get(`https://api.tvmaze.com/schedule/web?date=${today.getUTCFullYear()}-${(today.getUTCMonth() + 1).toString().padStart(2, "0")}-${today.getUTCDate().toString().padStart(2, "0")}`)
        .then(res => {
            return res.data.map((show: any) => {
                return {
                    id: show._embedded.show.id,
                    name: show.name + ' - ' + show._embedded.show.name,
                    img: show._embedded.show.image?.medium,
                }
            });
        })
        .catch(err => console.log(err));
}



const Homepage = () => {
    const [foundShows, setShows] = useState<Show[]>([]);
    const [foundCastList, setCastList] = useState<Cast[]>([]);
    const [upcomingShows, setUpcomingShows] = useState<Show[]>([]);
    const query = useQuery();

    useEffect(() => {
        upcomingShows.length === 0 && getUpcomingShows().then(shows => setUpcomingShows(shows));
    }, [upcomingShows.length]);
    useEffect(() => {
        
        const search = query.get('search');
        if (search) {
            onSearch(search);
        }
    });

        
    const onSearch = (value: string) => {
       
        const showsUrl = `https://api.tvmaze.com/search/shows?q=${value}`;
        const peopleUrl = `https://api.tvmaze.com/search/people?q=${value}`;

        const showsPromise = axios.get(showsUrl).then((response) => {
            const shows = response.data.map((show: any) => {
                return {
                    id: show.show.id,
                    name: show.show.name,
                    img: show.show.image?.medium,
                };
            });
            console.log(shows);
            setShows(shows);
        });
        const peoplePromise = axios.get(peopleUrl).then((response) => {
            const people = response.data.map((person: any) => {
                return {
                    id: person.person.id,
                    name: person.person.name,
                    img: person.person.image?.medium,
                };
            });
            console.log(people);
            setCastList(people);
        }
        );
        return Promise.all([showsPromise, peoplePromise]);

    }
    return (
        <div className={styles.homepage}>
            <SearchBar onSearch={onSearch} />
            {foundShows.length > 0 && <ShowsZone shows={foundShows} title="SHOWS"/>}
            {foundCastList.length > 0 && <CastZone castList={foundCastList} />}
            {upcomingShows.length > 0 && <ShowsZone shows={upcomingShows} title="UPCOMING"/>}
        </div>
    );
}
export default Homepage;





